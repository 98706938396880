import React, { useState, useEffect } from 'react';
import heroVideo from '../../../assets/hero_video.mov';
import mobileHeroVideo from '../../../assets/pastamaking.mp4';

const VideoComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 950);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 950);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const videoSrc = isMobile ? mobileHeroVideo : heroVideo;

  return (
    <div className="video-container">
      {isMobile && (
        <video
          className="hero-video"
          autoPlay
          loop
          muted
          playsInline
          disablePictureInPicture
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

export default VideoComponent;
